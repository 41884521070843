import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Constants from "../constants";
import Emoji from "../components/emoji";
import InternalLink from "../components/internal-link";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Span = styled.div`
  margin-top: 1rem;
`;

const AboutUs = ({
  location,
  data: { avatar, backdrop, logo, insta, site },
}) => (
  <Layout avatar={avatar} backdrop={backdrop} location={location} site={site}>
    <SEO
      avatar={avatar}
      description="Learn more about us, what we do with our free time, and our grand adventure"
      logo={logo}
      site={site}
      title="About us - Sheila Murray and James Simone's active travel blog - She & Jim"
      keywords={[
        "playing outside",
        "waking up early",
        "mountain air",
        "backpacking europe",
        "backpacking american west",
        "active travel",
        "yoga instructor",
        "sourdough bread",
        "active travel",
        "cooking dinner for friends",
      ]}
    />
    <Span>
      <h4>We are...</h4>
      James & Sheila <Emoji symbol="👋" label="hi" />.<h4>We met...</h4>
      as part of a running club at work and started dating while training for an
      ultramarathon. We both worked for an educational travel company at the
      time.
      <h4>We love and are passionate about...</h4>
      playing outside. Waking up early. Oats. Breathing mountain air. Growing
      and eating plants (plant-based diet). Actively exploring new places, even
      just down the street. Supporting local businesses and products that are
      ethically sourced. Producing our{" "}
      <InternalLink to="/blog/" color={Constants.Colors.internalLinks}>
        blog and related content
      </InternalLink>{" "}
      free of ads, so you can enjoy following along without being spammed.
      <h4>We fill our free time with...</h4>
      free time? Filling the minutes/hours/days with yoga (
      <strong>Sheila</strong> is a certified instructor), baking (sourdough
      bread, black bean brownies, new experiments), traveling (or otherwise
      dreaming up the next trip), the conversation & company of good friends
      (hit us up!)
      <h4>Whenever we go to a friend's place we ...</h4>
      get lost in their cookbooks. We also cook dinner for them if they'll let
      us.
      <h4>In 2019 we ...</h4>had a great backpacking adventure through Europe
      and the American West. We embraced the ups and downs of the nomadic
      lifestyle ... which eventually led to us settling down in Colorado. We'll
      be{" "}
      <InternalLink to="/timeline/" color={Constants.Colors.internalLinks}>
        charting our journey
      </InternalLink>
      , and{" "}
      <InternalLink to="/blog/" color={Constants.Colors.internalLinks}>
        sharing our anecdotes
      </InternalLink>
      .
    </Span>
  </Layout>
);

export const pageQuery = graphql`
  query AboutUsQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }
  }
`;

export default AboutUs;
